/* eslint-disable no-shadow */
import { useStorage } from '@vueuse/core';
import { Cookie } from '@common-vue/util/src/modules/cookie';
import { dispatchNavigationStoreChange } from '@common-vue/locator/src/utils/globalStoreChange';
import { defaultStoreName } from 'config';

import {
  SET_SDD_MESSAGE,
  SET_PREFERRED_STORE,
} from '../../types/mutations';
import {
  FETCH_SDD_MESESSAGE,
  FETCH_PREFERRED_STORE,
} from '../../types/actions';
import {
  GET_STORE_INFO,
  GET_STORE_OPERATION_INFO,
  GET_ZIP_CODE,
  GET_SDD_MESSAGE,
  GET_STORE_NAME,
  IS_STORE_SELECTED,
} from '../../types/getters';

import { logError } from '../../utils/Logger';
import { parseOperationHours } from '../../utils/commonStoreLocationUtil';
import { usePreferredStore } from '../../composables/dumb/usePreferredStore';
import {
  storeOperationStates,
  viewTypeToDeviceType,
} from '../../constants';

function normalizePreferredStore(data) {
  const { address } = data;

  if (!address) return data;

  const { line1: addrLine1, line2: addrLine2 } = address;

  return {
    ...data,
    address: {
      ...address,
      addrLine1,
      addrLine2,
    },
  };
}

function updatePreferredStoreCookies(newStore, forceUpdate) {
  const is365Customer = (Cookie.get('Login365Customer', 'MISCGCs') === 'Y');
  const expiryExtension = is365Customer ? 31556952000 : 2592000000;
  const cookieExpiry = new Date().getTime() + expiryExtension;
  const expires = new Date(parseInt(cookieExpiry, 10)).toUTCString();
  const latLong = `${newStore.geoLocation.latitude},${newStore.geoLocation.longitude}`;
  const { state, zipCode } = newStore.address;
  const normalizedZipCode = zipCode.split('-')[0];

  if (Cookie.get('CSL') && !forceUpdate) {
    return;
  }

  Cookie.set('CSL', newStore.locationNumber);
  Cookie.set('BOPSPICKUPSTORE', newStore.locationNumber, 'MISCGCs', {
    expires,
  });
  Cookie.set('USERLL', latLong, 'MISCGCs', {
    expires,
  });
  Cookie.set('USERPC', normalizedZipCode, 'MISCGCs', {
    expires,
  });
  Cookie.set('BTZIPCODE', normalizedZipCode, 'MISCGCs', {
    expires,
  });
  Cookie.set('USERST', state, 'MISCGCs', {
    expires,
  });
  Cookie.set('PDDST', state, 'MISCGCs', {
    expires,
  });
}

export function state() {
  return {
    preferredStore: {
      // Store name for MEW if store is not selected
      name: defaultStoreName,
    },
    sddMessage: null,
    locatorSlideoutRendered: false,
    stores: [],
    initialized: false,
  };
}

export const getters = {
  [GET_STORE_OPERATION_INFO]: (state) => parseOperationHours(state.preferredStore),
  [GET_STORE_INFO]: (state, getters) => {
    const storeOperationInfo = getters[GET_STORE_OPERATION_INFO];
    const isStoreOpen = storeOperationInfo.operationState.toLowerCase()
            === storeOperationStates.OPEN;

    return {
      name: state.preferredStore.name,
      address: state.preferredStore.address,
      id: state.preferredStore.id,
      schedule: state.preferredStore.schedule,
      phoneNumber: state.preferredStore.phoneNumber,
      locationNumber: state.preferredStore.locationNumber,
      operationState: storeOperationInfo.operationState,
      operationHours: storeOperationInfo.operationHours,
      isOpen: isStoreOpen,
      sddMessage: state.sddMessage,
    };
  },
  [GET_ZIP_CODE]: (state) => state.preferredStore.address?.zipCode,
  [GET_SDD_MESSAGE]: (state) => state.sddMessage,
  [GET_STORE_NAME]: (state) => state.preferredStore.name || defaultStoreName,
  [IS_STORE_SELECTED]: (_, getters) => getters[GET_STORE_NAME] !== defaultStoreName,
};

export const mutations = {
  [SET_SDD_MESSAGE](state, payload) {
    state.sddMessage = payload;
  },
  [SET_PREFERRED_STORE](state, payload) {
    state.preferredStore = payload;
  },
  renderLocatorSlideout(state) {
    state.locatorSlideoutRendered = true;
  },
  setInitialized(state) {
    state.initialized = true;
  },
};

export const actions = {
  async [FETCH_PREFERRED_STORE]({ commit, rootState }) {
    const storageValue = useStorage('commonstorelocation', {});
    const misCookie = Cookie.get('MISCGCs');
    const csl = Cookie.get('CSL');
    const userPC = Cookie.get('USERPC', 'MISCGCs');
    const btZipcode = Cookie.get('BTZIPCODE', 'MISCGCs');
    const bopsPickupStore = Cookie.get('BOPSPICKUPSTORE', 'MISCGCs');

    if (Object.keys(storageValue.value).length && misCookie && csl && userPC && btZipcode && bopsPickupStore) {
      commit(SET_PREFERRED_STORE, storageValue.value);
      updatePreferredStoreCookies(storageValue.value);
      commit('setInitialized');
      return;
    }

    const { hostlink } = rootState.envProps;
    const { fetchPreferredStore } = usePreferredStore({ hostlink });
    const { guid, storeInfo } = rootState.navUser.data;
    const { viewType } = rootState.navViewTypeStore;
    const { latLng } = storeInfo;
    const deviceType = viewTypeToDeviceType[viewType];

    const isLatLngAvailable = latLng && Object.keys(latLng).length;
    if (!isLatLngAvailable) {
      commit('setInitialized');
      return;
    }

    try {
      const { fetch, result } = fetchPreferredStore({
        latLng,
        deviceType,
        guid,
      });
      await fetch();
      if (result.value && result.value.preferredStore) {
        const newStore = result.value.preferredStore;
        const data = normalizePreferredStore(newStore);

        commit(SET_PREFERRED_STORE, data);
        storageValue.value = data;
        updatePreferredStoreCookies(newStore, true);
        dispatchNavigationStoreChange(); // needed to notify DnF component when we change store
      }
    } catch (e) {
      logError(e?.message);
    } finally {
      commit('setInitialized');
    }
  },
  async [FETCH_SDD_MESESSAGE]({ commit, rootState }, zipCode) {
    const { hostlink } = rootState.envProps;
    const { fetchSDDMessage } = usePreferredStore({ hostlink });

    try {
      const { fetch, result } = fetchSDDMessage({ zipCode });
      await fetch();
      const ssdMessage = result.value?.stores?.store[0]?.sddMessage;
      if (result.value && ssdMessage) {
        commit(SET_SDD_MESSAGE, ssdMessage);
      }
    } catch (e) {
      logError(e?.message);
    }
  },
  toggleLocatorSlideout({ commit, state }) {
    if (state.locatorSlideoutRendered) {
      commit('slideoutStore/TOGGLE', 'locator-slideout', { root: true });
    } else {
      commit('renderLocatorSlideout');
    }
  },
};
