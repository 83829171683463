<template>
  <Skeleton
    v-if="!isInitialized"
    height="24px"
    width="24px"
    class="margin-left-xs"
  />
  <button
    v-else-if="isStoreSelected"
    class="rail-btn-hover rail-btn-nowrap margin-left-xs"
    @click="openStoreSlideout"
    @mouseenter="fireAnalytics"
  >
    <i class="icon-24x24 store-icon-condensed margin-right-xxs" />
    <AsideText>
      <template #title>
        Your store
      </template>
      <template #text>
        {{ zipCode }}
      </template>
    </AsideText>
  </button>
  <button
    v-else
    class="rail-btn-hover rail-btn-nowrap margin-left-xs"
    @click="openStoreSlideout"
  >
    <i class="icon-24x24 store-icon-condensed" />
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Skeleton from '@atomic-ui/skeleton';
import { IS_STORE_SELECTED, GET_ZIP_CODE } from '../../types/getters';
import AsideText from './AsideText.common';
import { fireLinkTag } from '../../features/analytics/tagManager';
import { onPreferredStoreReady } from '../../events';

const isInitialized = ref(false);
const store = useStore();
const isStoreSelected = computed(() => store.getters[`navPreferredStore/${IS_STORE_SELECTED}`]);
const zipCode = computed(() => store.getters[`navPreferredStore/${GET_ZIP_CODE}`]);

const openStoreSlideout = () => store.dispatch('navPreferredStore/toggleLocatorSlideout');
const fireAnalytics = () => {
  const payload = {
    event_name: 'store header change',
    link_name: 'store header change hover',
  };
  fireLinkTag(payload);
};
onPreferredStoreReady(() => {
  isInitialized.value = true;
});
</script>
